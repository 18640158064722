<template>
  <div>
    <chat-bot-icon />
    <div v-if="staticPage.id" class="bg-cl-secondary py35 pl20">
      <div class="container">
        <breadcrumbs :with-homepage="true" :routes="[]" :active-route="staticPage.title" />
        <h1 class="fs-big">
          {{ staticPage.title }}
        </h1>
      </div>
    </div>

    <div class="container pt45 pb70">
      <div class="row pl20 pr20 pt0">
        <div class="col-md-3">
          <nav class="static-menu serif h4 mb35">
            <ul class="m0 p0 pr5">
              <li class="mb10" v-for="route in routes" :key="route.id">
                <router-link :to="localizedRoute(route.link)" class="cl-accent relative">
                  {{ route.title }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-8">
          <div class="static-content h4 lh30">
            <static-content
              v-if="staticPage.id"
              :id="staticPage.id"
              :title="staticPage.title"
              :page-content="staticPage.pageContent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import StaticContent from 'theme/components/theme/StaticContent.vue'
import { selfCanonical } from '../helpers/meta/selfCanonical';
import ChatBotIcon from '../components/core/blocks/ChatBot/ChatBotIcon';
import { mapGetters, mapState } from 'vuex';
import config from 'config';
import { Logger } from '@vue-storefront/core/lib/logger';
import MENU_ITEMS_QUERY from '../graphql/strapi/queries/menuItems.gql';
import STATIC_PAGE_QUERY from '../graphql/strapi/queries/staticPage.gql';

const transformMenuItemsResponseToRoutes = (menuItemsQueryResponse) => {
  const routes = menuItemsQueryResponse.data.staticPageMenus.data[0].attributes.StaticPages.data
    .map((staticPage) => {
      const { id, attributes: { Title, Slug, MetaDescription } } = staticPage;

      return {
        id,
        title: Title,
        metaDescription: MetaDescription,
        link: '/static/' + Slug
      };
    });

  return routes;
};

const transformStaticPageQueryResponse = (staticPageQueryResponse) => {
  const {
    id,
    attributes: {
      MetaDescription,
      Title,
      PageContent
    }
  } = staticPageQueryResponse.data.staticPages.data[0];

  return {
    id,
    metaDescription: MetaDescription,
    title: Title,
    pageContent: PageContent
  };
}

export default {
  name: 'Static',
  components: {
    Breadcrumbs,
    ChatBotIcon,
    StaticContent
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || this.$props.title,
      meta: this.staticPage.id ? [
        {
          vmid: 'description',
          description: this.staticPage.metaDescription
        }
      ] : [],
      link: [
        selfCanonical(this.$route)
      ]
    }
  },
  props: {
    title: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      routes: [],
      staticPage: {
        id: undefined,
        metaDescription: undefined,
        title: undefined,
        pageContent: []
      }
    };
  },
  computed: {
    ...mapState({
      isChatBotOpen: state => state.ui.chatBot
    })
  },
  methods: {
    fetchMenuItems (partnerId) {
      return new Promise(async (resolve, reject) => {
        try {
          const menuItemsQueryResponse = await this.$apollo.query({
            query: MENU_ITEMS_QUERY,
            variables: {
              filters: {
                Partner: {
                  Name: {
                    eq: partnerId
                  }
                }
              }
            }
          });

          this.routes = transformMenuItemsResponseToRoutes(menuItemsQueryResponse);
          resolve();
        } catch (error) {
          Logger.error('Cannot load menu items on static page: ' + error);
          reject(error);
        }
      });
    },
    fetchStaticPage (partnerId, slug) {
      return new Promise(async (resolve, reject) => {
        if (!partnerId || !slug) {
          reject();
        }

        try {
          const staticPageQueryResponse = await this.$apollo.query({
            query: STATIC_PAGE_QUERY,
            variables: {
              filters: {
                Slug: {
                  eq: slug
                },
                Partner: {
                  Name: {
                    eq: partnerId
                  }
                }
              }
            }
          });

          this.staticPage = transformStaticPageQueryResponse(staticPageQueryResponse);
          resolve();
        } catch (error) {
          Logger.error('Cannot load static page content: ', error)
          reject(error);
        }
      });
    },
    getSlugFromPath (path) {
      return path.slice(path.lastIndexOf('/') + 1);
    }
  },
  serverPrefetch () {
    const { id: partnerId } = this.$ssrContext.partnerData;

    return Promise.all([
      this.fetchMenuItems(partnerId),
      this.fetchStaticPage(
        partnerId,
        this.getSlugFromPath(this.$route.path)
      )
    ]);
  },
  async mounted () {
    this.fetchMenuItems(config.partnerData.id);
  },
  watch: {
    '$route.path': {
      immediate: true,
      deep: true,
      handler: function (path) {
        this.fetchStaticPage(
          config.partnerData.id,
          this.getSlugFromPath(path)
        );
      }
    },
    '$route.hash': {
      immediate: true,
      deep: true,
      handler: function (hash) {
        if (hash === '#contact-form') {
          this.$store.dispatch('ui/setChatBot', true);
        }
      }
    },
    isChatBotOpen: {
      handler: function (isChatBotOpen) {
        if (!isChatBotOpen) {
          this.$router.push(this.$route.path);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$border-primary: color(primary, $colors-border);

.static-menu {
  ul {
    list-style: none;
  }

  a {
    text-decoration: underline;
    text-decoration-color: $border-primary;
  }

  a:hover {
    text-decoration-color: transparent;
  }

  .router-link-active {
    font-family: 'TTCommons-Bold', sans-serif;
    text-decoration-color: transparent;
  }
}

.static-content {
  *:first-of-type {
    margin-top: 0;
  }
}

</style>

<style lang="scss">
.static-content {
  h3 {
    margin-top: 40px;
    margin-bottom: 25px;

    @media (max-width: 767px) {
      margin-top: 35px;
      margin-bottom: 10px;
    }
  }

  strong {
    font-family: 'TTCommons-DemiBold', sans-serif;
  }
}
</style>

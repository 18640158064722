<template>
  <div class="wrapper">
    <table class="wrapper">
      <tr>
        <th class="px5">
          Výžka postavy (cm)
        </th>
        <th colspan="4">
          Horská kola
        </th>
        <th colspan="4">
          Treková kola
        </th>
        <th colspan="6">
          Silniční kola
        </th>
        <!--      <th>Výška postavy (cm)</th>-->
      </tr>
      <tr>
        <td>150</td>
        <td class="center text-cell color-white-smoke" rowspan="3">
          S<br>15,5″
        </td>
        <td colspan="3" />
        <!--          -->
        <td class="center text-cell color-white-smoke" rowspan="3">
          S<br>16″
        </td>
        <td colspan="3" />
        <!--          -->
        <td class="center text-cell color-white-smoke" rowspan="2">
          49<br>cm
        </td>
        <td colspan="5" />
      </tr>
      <tr>
        <td>155</td>
        <td colspan="3" />
        <!--          -->

        <td colspan="3" />
        <!--          -->
        <td colspan="5" />
        <!--    -->
        <!--      <td>155</td>-->
      </tr>
      <tr>
        <td>160</td>
        <td colspan="3" />
        <!--          -->
        <td class="center text-cell color-anyrent" rowspan="3">
          M<br>17,5″
        </td>
        <td colspan="2" />
        <!--          -->
        <td />
        <td class="center text-cell color-anyrent" rowspan="3">
          52<br>cm
        </td>
        <td colspan="4" />
        <!--    -->
        <!--      <td>160</td>-->
      </tr>
      <tr>
        <td>165</td>
        <td />
        <td class="center text-cell color-anyrent" rowspan="3">
          M<br>17,5″
        </td>
        <td colspan="2" />
        <!--          -->
        <td />
        <td colspan="2" />
        <!--          -->
        <td />
        <td colspan="4" />
        <!--    -->
        <!--      <td>165</td>-->
      </tr>
      <tr>
        <td>170</td>
        <td />
        <td colspan="2" />
        <!--          -->
        <td />
        <td class="center text-cell color-white-smoke" rowspan="3">
          L<br>19,5″
        </td>
        <td />
        <!--          -->
        <td />
        <td class="center text-cell color-white-smoke" rowspan="3">
          54<br>cm
        </td>
        <td colspan="3" />
        <!--    -->
        <!--        <td>170</td>-->
      </tr>
      <tr>
        <td>175</td>
        <td />

        <td class="center text-cell color-white-smoke" rowspan="3">
          L<br>19,5″
        </td>
        <td />
        <!--          -->
        <td colspan="2" />
        <td />

        <td colspan="2" />
        <td colspan="3" />
        <!--    -->
        <!--        <td>175</td>-->
      </tr>
      <tr>
        <td>180</td>
        <td colspan="2" />
        <td />
        <!--          -->
        <td colspan="2" />
        <td />
        <!--          -->
        <td colspan="2" />
        <td class="center text-cell color-anyrent" rowspan="2">
          56<br>cm
        </td>
        <td colspan="2" />
      </tr>
      <tr>
        <td>185</td>
        <td colspan="2" />
        <td />
        <!--          -->
        <td colspan="3" />
        <td class="center text-cell color-anyrent" rowspan="2">
          XL<br>21,5″
        </td>
        <!--          -->
        <td colspan="3" />
        <td class="center text-cell color-white-smoke" rowspan="2">
          58<br>cm
        </td>
        <td />
        <!--    -->
        <!--        <td>180</td>-->
      </tr>
      <tr>
        <td>190</td>
        <td colspan="3" />
        <td class="center text-cell color-anyrent" rowspan="2">
          XL<br>21,5″
        </td>
        <!--          -->
        <td colspan="3" />
        <!--          -->
        <td colspan="4" />
        <td class="center text-cell color-anyrent" rowspan="3">
          61<br>cm
        </td>
        <!--    -->
        <!--        <td>190</td>-->
      </tr>
      <tr>
        <td>195</td>
        <td colspan="3" />
        <!--          -->
        <td colspan="3" />
        <td class="text-cell color-white-smoke" rowspan="2">
          XXL 23″
        </td>
        <!--          -->
        <!--      <td colspan='5'></td>-->
        <!--    -->
        <!--        <td>195</td>-->
      </tr>
      <tr>
        <td>nad 200</td>
        <td colspan="3" />
        <td class="text-cell color-white-smoke">
          XXL 23″
        </td>
        <!--          -->
        <td colspan="3" />
        <!--          -->
        <td colspan="5" />
        <!--    -->
        <!--        <td>nad 200</td>-->
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'BikeTable'
};
</script>

<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-anyrent: color(anyrent);
$white-smoke: color(white-smoke);
table, tr, td, th {
  font-size: 16px;
  border: 1px solid #afafaf;
  border-collapse: collapse;
  text-align: center;
}

tr:nth-child(even) {
  background: #fcfcfc;
}

.color-anyrent {
  background: $color-anyrent
}

.color-white-smoke {
  background: $white-smoke;
}

.text-cell {
  width: 50px;
  min-width: 30px;
  text-align: center;
}

.center {
  text-align: center;
}

.wrapper {
  overflow: scroll
}
.px5 {
  padding: 0px 5px;
}

</style>

<template>
  <div class="static-page">
    <div v-for="dynamicComponent in pageContent">
      <div
        class="pickup-point-viewer-container"
        v-if="dynamicComponent.__typename === 'ComponentStaticPageMap'"
      >
        <pickup-points-viewer
          :mode="displayPartnerPages() ? '' : 'switch'"
          :full-screen-mobile="false"
          :pickup-points="pickupPoints.map(pp=>pp)"
        />
      </div>

      <VueShowdown
        v-if="dynamicComponent.__typename === 'ComponentStaticPageMarkdown'"
        :markdown="dynamicComponent.Markdown"
        :options="{ tables: true }"
      />

      <bike-table
        v-if="dynamicComponent.__typename === 'ComponentStaticPageBikeSizeTable'"
      />
    </div>
  </div>
</template>

<script>
import { VueShowdown } from 'vue-showdown';
import BikeTable from './blocks/Static/BikeTable.vue';
import PickupPointsViewer from '../core/blocks/PickupPoint/PickupPointsViewer';
import config from 'config';

export default {
  name: 'StaticContent',
  components: {
    VueShowdown,
    PickupPointsViewer,
    BikeTable
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    pageContent: {
      type: Array,
      required: true
    }
  },
  computed: {
    pickupPoints () {
      return this.$store.getters['partner/getPickupPoints'];
    }
  },
  data () {
    return {}
  },
  methods: {
    displayPartnerPages () {
      return config.partnerData.displayPartnerPages;
    }
  }
}
</script>

<style lang="scss">
@import '~theme/css/helpers/functions/color';
@import '~theme/css/variables/colors';

.pickup-point-viewer-container {
    width: 100%;
    height: 500px;
    padding-bottom: 50px;
}

.static-page {
  a {
    text-decoration: underline;

    &:hover {
      color: color(partner);
    }
  }

  img {
    max-width: 100%;
  }

  table {
    width: 100%;

    th {
      text-align: left;
    }

    tr {
      td:nth-of-type(1) {
        width: 200px;
      }

      td:nth-of-type(2) {
        color: rgb(70,70,70);
      }
    }
  }
}

</style>
